export {
  SessionData
}

const SessionData = [
  {
    // userID:'111111111',
    boardData:[
      {
        boardId:'111111',//黑板id
        boardType:'10',//黑板类型
        boardName:'黑板1',//黑板名称
        unReadNum:'0',//未读消息
        messageList:[
          // {
          //   id:'111111001',
          //   boardId:'111111',
          //   boardType:'10',
          //   messageStatus:1,//消息状态 0.未读 1.已读 
          //   messageType:2, // 1 文字，2 图片， 3 语音 , 4 擦除 5 画板数据 6 清除黑板
          //   body:{
          //     message:'这是一段话!',
          //     url:require("@/assets/Starry.jpg"),
          //   },
          //   from:{
          //     userId:'111',
          //     userName:'111',
          //     sendTime:'1690877065161',
          //   },
          //   to:{},
          // },
          // {
          //   id:'111111002',
          //   boardId:'111111',
          //   boardType:'10',
          //   messageStatus:1,//消息状态 0.未读 1.已读 
          //   messageType:2, // 1 文字，2 图片， 3 语音 , 4 擦除 5 画板数据 6 清除黑板
          //   body:{
          //     message:'这是一段话!',
          //     url:require("@/assets/Starry.jpg"),
          //   },
          //   from:{
          //     userId:'111',
          //     userName:'111',
          //     sendTime:'1690877066161',
          //   },
          //   to:{},
          // },
          // {
          //   id:'111111003',
          //   boardId:'111111',
          //   boardType:'10',
          //   messageStatus:1,//消息状态 0.未读 1.已读 
          //   messageType:2, // 1 文字，2 图片， 3 语音 , 4 擦除 5 画板数据 6 清除黑板
          //   body:{
          //     message:'这是一段话!',
          //     url:require("@/assets/Starry.jpg"),
          //   },
          //   from:{
          //     userId:'111',
          //     userName:'111',
          //     sendTime:'1690877067161',
          //   },
          //   to:{},
          // },
          // {
          //   id:'111111004',
          //   boardId:'111111',
          //   boardType:'10',
          //   messageStatus:1,//消息状态 0.未读 1.已读 
          //   messageType:2, // 1 文字，2 图片， 3 语音 , 4 擦除 5 画板数据 6 清除黑板
          //   body:{
          //     message:'这是一段话!',
          //     url:require("@/assets/Starry.jpg"),
          //   },
          //   from:{
          //     userId:'111',
          //     userName:'111',
          //     sendTime:'1690877068161',
          //   },
          //   to:{},
          // },
          // {
          //   id:'111111005',
          //   boardId:'111111',
          //   boardType:'10',
          //   messageStatus:1,//消息状态 0.未读 1.已读 
          //   messageType:2, // 1 文字，2 图片， 3 语音 , 4 擦除 5 画板数据 6 清除黑板
          //   body:{
          //     message:'这是一段话!',
          //     url:require("@/assets/Starry.jpg"),
          //   },
          //   from:{
          //     userId:'111',
          //     userName:'111',
          //     sendTime:'1690877069161',
          //   },
          //   to:{},
          // },
          // {
          //   id:'111111006',
          //   boardId:'111111',
          //   boardType:'10',
          //   messageStatus:1,//消息状态 0.未读 1.已读  101 图片上传加载中
          //   messageType:2, // 1 文字，2 图片， 3 语音 , 4 擦除 5 画板数据 6 清除黑板
          //   body:{
          //     message:'这是一段话!',
          //     url:require("@/assets/Starry.jpg"),
          //   },
          //   from:{
          //     userId:'111',
          //     userName:'111',
          //     sendTime:'1690877075161',
          //   },
          //   to:{},
          // },
          // {
          //   id:'111111007',
          //   boardId:'111111',
          //   boardType:'10',
          //   messageStatus:1,//消息状态 0.未读 1.已读 
          //   messageType:2, // 1 文字，2 图片， 3 语音 , 4 擦除 5 画板数据 6 清除黑板
          //   body:{
          //     message:'这是一段话!',
          //     url:require("@/assets/Starry.jpg"),
          //   },
          //   from:{
          //     userId:'111',
          //     userName:'111',
          //     sendTime:'1690877085161',
          //   },
          //   to:{},
          // },
        ],
      },
      {
        boardId:'111112',//黑板id
        boardType:'11',//黑板类型
        boardName:'黑板2',//黑板名称
        unReadNum:'0',//未读消息
        messageList:[
          // {
          //   id:'111111002',
          //   boardId:'111112',
          //   boardType:'11',
          //   messageStatus:1,//消息状态 0.未读 1.已读 
          //   messageType:1,
          //   body:{
          //     message:'这是一段话!',
          //     url:"",
          //   },
          //   from:{
          //     userId:'',
          //     userName:'',
          //     sendTime:'',
          //   },
          //   to:{},
          // },
        ],
      },
      {
        boardId:'111113',//黑板id
        boardType:'12',//黑板类型
        boardName:'黑板3',//黑板名称
        unReadNum:'0',//未读消息
        messageList:[
          // {
          //   id:'111111003',
          //   boardId:'111113',
          //   boardType:'12',
          //   messageStatus:1,//消息状态 0.未读 1.已读 
          //   messageType:1,
          //   body:{
          //     message:'这是一段话!',
          //     url:"",
          //   },
          //   from:{
          //     userId:'',
          //     userName:'',
          //     sendTime:'',
          //   },
          //   to:{},
          // },
        ],
      },
      // {
      //   boardId:'111114',//黑板id
      //   boardType:'10',//黑板类型
      //   boardName:'黑板4',//黑板名称
      //   unReadNum:'1',//未读消息
      //   messageList:[
      //     // {
      //     //   id:'111111001',
      //     //   boardId:'111111',
      //     //   boardType:'10',
      //     //   messageStatus:1,//消息状态 0.未读 1.已读 
      //     //   messageType:1, // 1 文字，2 图片， 3 语音 , 4 擦除 5 画板数据
      //     //   body:{
      //     //     message:'这是一段话!',
      //     //     url:"",
      //     //   },
      //     //   from:{
      //     //     userId:'',
      //     //     userName:'',
      //     //     sendTime:'',
      //     //   },
      //     //   to:{},
      //     // },
      //   ],
      // },
    ]
  },
]