// import { login, logout, getInfo, getpermcode } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { loginData, infoData } from '@/utils/data'
// import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  accountType:'',
  roles: [],
  userData:{},
  permcode:[],
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_REFRESHTOKEN: (state, token) => {
    state.refreshToken = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_USERDATA: (state, userData) => {
    console.log(userData);
    state.userData = userData
  },
  SET_PERMCODE: (state, permcode) => {
    state.permcode = permcode
  },
  SET_ACCOUNTTYPE: (state, accountType) => {
    state.accountType = accountType
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { userName, passWord } = userInfo
    return new Promise((resolve, reject) => {
      let flag = false
      let token = ''
      loginData.map((item, index) => {
        if(userName == item.username){
          if(passWord == item.password){
            token = item.token
            flag = true
          }
        }
      })
      if(!flag) reject()

      // login({ account: username.trim(), password: password }).then(response => {
        commit('SET_TOKEN','Bearer ' + token)
        setToken('Bearer ' + token)
        resolve()
      // }).catch(error => {
      //   reject(error)
      // })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      let flag = false
      let userData = {}
      infoData.map((item, index) => {
        if(getToken() == item.token){
          userData = {
            userName:item.userName,
            userID:item.userID,
          }
          flag = true
        }
      })
      if(!flag) reject()
      // getInfo().then(response => {
        // let roles = ['admin']
        // let name = response.result.realName
        // let avatar ='https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
        // let  introduction = 'I am a super administrator'
        // let userData = response.result
        
        // let data = {
        //   roles,
        //   name,
        //   avatar,
        //   introduction,
        //   userData
        // }
        // commit('SET_ROLES', roles)
        // commit('SET_NAME', name)
        // commit('SET_AVATAR', avatar)
        // commit('SET_INTRODUCTION', introduction)
        commit('SET_USERDATA', userData)
        // commit('SET_ACCOUNTTYPE',response.result.accountType)
      //   console.log(data);
        console.log(userData);
        resolve(userData)
      // }).catch(error => {
      //   removeToken()
      //   reject(error)
      // })
    })
  },

  //获取按钮权限
  getPermCode({ commit, state }){
    return new Promise((resolve, reject) => {
      getpermcode().then(response => {
        // console.log(response);
        commit('SET_PERMCODE', response.result)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout().then(() => {

        commit('SET_TOKEN', '')
        removeToken()
        // resetRouter()

        resolve()
        // location.reload()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
