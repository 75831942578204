import Vue from 'vue'
import Vuex, {
  Store
} from 'vuex'
import user from './modules/user'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    callStatus: false,
    callOptions:null,
  },
  getters: {},
  mutations: {
    CallStatusChange(state, data) {
      state.callStatus = data
    },
    CallOptionsChange(state, data) {
      state.callOptions = data
    },
  },
  actions: {},
  modules: {
    user,
  }
})
export default store