import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index.vue')
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import(/* webpackChunkName: "index" */ '@/views/login/index.vue')
  // },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "index" */ '@/views/test/test.vue')
  },
  {
    path: '/groupChat',
    name: 'groupChat',
    component: () => import(/* webpackChunkName: "index" */ '@/views/test/groupChat.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
